<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import StarRating from "vue-star-rating";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
//import {reactive} from "vue";
import Multiselect from "vue-multiselect";
import { format } from "date-fns";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Information utilisateur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
   StarRating,
    VueEasyLightbox,
    ckeditor: CKEditor.component,
    Multiselect,
  },
  data() {
    return {
      title: "Ajouter utilisateur",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      firstname: "",
      conducteur: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passagers", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passager", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],

      autoPartage: [
        { key: "immatricule", sortable: true, label: "Date de début" },
        { key: "model", sortable: true, label: "Date prévu de fin" },
        { key: "marque", sortable: true, label: "Date de fin " },
        { key: "assurance", sortable: true, label: "Assurance" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "client", sortable: true, label: "Client" },
        { key: "statut", sortable: true, label: "Statut" },
      ],

      tokenUser: JSON.parse(localStorage.getItem("user")).tokenSalt,
      idUser: 0,
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      AutoMoto: {
        climatisation: "",
        anneeCirculation: "",
        categorie: "",
        couleur: "",
        immatriculation: "",
        model: "",
        motorisation: "",
        nomAuto: "",
        cartegrise: "",
        nombresiege: "",
        clime: false,
        prixJ: "",
        prixJHorsVille: "",
        selectedModel: null,
        compagnieDassur: null,
        boite: null,
        lieu: null,
      },
      fileProfil: null,
      filePermis: null,
      fileMoto: null,
      fileCni: null,
      user: {
        username: "",
        usertype: "",
        tel: "",
        email: "",
        levelRate: "",
        level: "",
        rating: "",
        nombreVote: "",
        active: "",
        badge: "",
        solde: 0,

        villeresidence: "",
        apropos: "",
        villeHabite: "",

        latWorkPlace:0.0,
        lngWorkPlace:0.0,
        latitude : 0.0,
        longitude : 0.0,
        personneUrgence:"",
        contactUrgence:"",
        indicatifContactTel:""
      },
      preference: {
        acceptHandicape: "",
        discutIncar: "",
        eatIncar: "",
        musicIncar: "",
        smoke: "",
        stop: "",
      },
      verify: {
        email: false,
        permis: false,
        autoMoto: false,
        image: false,
        tel: false,
        cni: false,
      },
      activity: {
        publies: 0,
        annulees: 0,
        a_venir: 0,
        effectues: 0,
        gains: 0,
        reservations_annulees: 0,
        voyages_effectues: 0,
        reservations_effectuees: 0,
      },
      autopartageStat: {
        nombreLocation: 0,
        gain: 0,
        reserveAnnuler: 0,
      },
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      location: {
        model: null,
        prixJournalier: null,
        prixJournalierHorsVille: null,
        boite: null,
        compagnieAssurance: null,
        lieu: null,
        numAssurance: null,
      },

      showretire: false,
      showSucess: false,
      showSucessM: false,
      showEchec: false,
      showEchecM: false,
      userDesac: null,
      avislaisses: [],
      avisrecus: [],
      conducteurData: [],
      autopartageData: [],
      passagerData: [],
      img: "",
      vue: false,
      visible: false,
      showModalprofil: false,
      showModalcni: false,
      showModalpermis: false,
      showModalautoMoto: false,
      HistoriqueConducteur: false,
      HistoriquePassager: false,
      HistoriqueAutopartage: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      url_link: "",
      userTypeSelect: "",
      tyMedia: [
        {
          name: "",
          value: 0,
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],
      showModalChangePass: false,
      showModalChangeName: false,
      showChange: false,
      showEchecChange: false,
      newpassword: "",
      confnewpassword: "",
      showUpdate: false,
      selected: null,
      options: [
        { value: "MANUELLE", text: "Manuelle" },
        { value: "AUTO", text: "Automatique" },
      ],
      indicatif: "",
      otherLocationList: [],
      otherVehiculeList: [],
      actualImageSrc: null,
      showDelete: false,
      idVehicule: 0,
      totalAutoPartage: 0,
      list_other_vehicule:[],
      showModalChangeLieuTravail: false,
      showModalChangeEmail: false,
      showModalWorkspace: false,
      showModalChangeApropo: false,
      showModalChangeUrgence:false,
      gainCurrentUser:{
        "attente":0,
        "total":0,
        "approuve":0,
        "rejete":0,
      },
      showModalChangeTel:false,
     

    };
  },
  validations: {
    firstname: { required },
    lastname: { required },
    genre: { required },
    number_phone: { required, numeric },
    email: { required, email },
    password: { required },

    tooltipform: {
      fname: { required },
      lname: { required },
      username: { required },
      city: { required },
      state: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },

  async mounted() {
    this.init();
    this.getHistorique();
    this.loadUserGain();
  },
  computed: {
    /**
     * Total no. of records
     */
    /*   rows() {
          return this.dataUser.length;
        }, */
  },

  methods: {
    async init() {
      this.idUser = this.$route.params.idUser;

      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/rmo/detail?id=" + this.idUser,false
      );
      console.log("data on init")
      console.log("data on init")
      console.log("data on init")
      console.log("data on init")
      console.log("data on init")
      console.log("data on init", data.dat)

      if (data && data.data) {
        console.log("data.data of admin/rmo/detail", data);
        this.user.username =
          data.data.personne.lastname + " " + data.data.personne.firstname;
        this.lastname = data.data.personne.lastname;

        this.fisrtname = data.data.personne.firstname;
        this.user.usertype = data.data.userType;
        this.user.tel =
          data.data.personne.tel && data.data.personne.tel != ""
            ?  data.data.personne.tel
            : "";
        this.user.email = data.data.personne.email;
        this.user.levelRate = data.data.levelRate;
        this.user.level = data.data.level;
        this.user.rating = data.data.rating ? data.data.rating : "0";
        this.user.nombreVote = data.data.nombreVote
          ? data.data.nombreVote
          : "0";

        this.user.indicatifContactTel = data.data.personne.indicatifContactTel
          ? data.data.personne.indicatifContactTel
          : "";
          this.tel =
          data.data.personne.indicatifContactTel && data.data.personne.indicatifContactTel != ""
            ?  data.data.personne.tel
            : "";
          this.indicatif =
          data.data.personne.indicatifContactTel && data.data.personne.indicatifContactTel != ""
            ? data.data.personne.indicatifContactTel
            : "";
        this.user.personneUrgence = data.data.personne.personneUrgence
          ? data.data.personne.personneUrgence
          : "";
        this.user.contactUrgence = data.data.personne.contactUrgence
          ? data.data.personne.contactUrgence
          : "";

        this.user.solde = data.data.wallet.solde;

        this.user.active = data.data.active;
        this.user.badge = data.data.userValidated;
        this.verify.email = data.data.emailVerified;
        this.verify.permis = data.data.permisVerified;
        this.verify.autoMoto = data.data.autoMotoVerified;
        this.verify.image = data.data.pictureVerified;
        this.verify.tel = data.data.telephoneVerified;
        this.verify.cni = data.data.cniVerified;
        this.otherLocationList = data.data.locationAutresVehicules;
        this.otherVehiculeList = data.data.autreVehicules;
        this.user.apropos = data.data.personne.apropos;
        this.user.villeHabite = data.data.personne.adresse;
        this.user.villeresidence = data.data.personne.workPlaceName;

        if (data.data.location) {
         
          this.location.boite = data.data.location.boite;
          this.location.lieu = data.data.location.lieu;
          this.location.numAssurance = data.data.location.numeroAssurance;
          this.location.model = data.data.location.model;
          this.location.prixJournalier =
            data.data.location.prixPublieJournalier;
          this.location.prixJournalierHorsVille =
            data.data.location.prixPublieJournalierHorsVille;
          this.location.compagnieAssurance =
            data.data.location.compagnieAssurance;
        }
        if (data.data.preferences) {
          (this.preference.acceptHandicape =
            data.data.preferences.acceptPeopleDisabilities),
            (this.preference.discutIncar = data.data.preferences.discussInCar),
            (this.preference.eatIncar = data.data.preferences.eatInCar),
            (this.preference.musicIncar =
              data.data.preferences.enjoyMusicInCar),
            (this.preference.smoke = data.data.preferences.smokeInCar),
            (this.preference.stop = data.data.preferences.tolerateStops);
        }

        if (data.data.vehicule) {
          this.AutoMoto.climatisation =
            data.data.vehicule.aClimatisation == true ? "Oui" : "Non";
          this.AutoMoto.clime = data.data.vehicule.aClimatisation;
          this.AutoMoto.anneeCirculation =
            data.data.vehicule.autoMotoAnneeMiseEnCirculation;
          this.AutoMoto.categorie = data.data.vehicule.autoMotoCategorie;
          this.AutoMoto.couleur = data.data.vehicule.autoMotoCouleur;
          this.AutoMoto.immatriculation =
            data.data.vehicule.autoMotoImmatriculation;
          this.AutoMoto.model = data.data.vehicule.autoMotoModel;
          this.AutoMoto.marque = data.data.vehicule.autoMotoName;
          
          this.AutoMoto.motorisation = data.data.vehicule.autoMotoMotorisation;
          this.AutoMoto.nomAuto = data.data.vehicule.autoMotoName;
          //this.AutoMoto.cartegrise = data.data.vehicule.autoMotoNumeroCarteGrise;
          this.AutoMoto.nombresiege = data.data.vehicule.nombreSieges;
        }

        if (data.data.userAvatarCode !== null) {
          this.allImage.avatar =
            `https://rmobility.ovh/admin/image/?codeImage=` +
            data.data.userAvatarCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.avatar = require("@/assets/images/users/attente.png");
        }

        if (data.data.userCniCode) {
          this.allImage.cni =
            `https://rmobility.ovh/admin/image/?codeImage=` +
            data.data.userCniCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.cni = require("@/assets/images/users/attente.png");
        }

        if (data.data.vehicule) {
          if (
            data.data.vehicule.autoMotoPermisCode !== null &&
            this.tokenUser !== null
          ) {
            this.allImage.permis =
              `https://rmobility.ovh/admin/image/?codeImage=` +
              data.data.vehicule.autoMotoPermisCode +
              `&token=` +
              this.tokenUser;
          } else {
            this.allImage.permis = require("@/assets/images/users/attente.png");
          }
        } else {
          this.allImage.permis = require("@/assets/images/users/attente.png");
        }

        if (data.data.vehicule) {
          if (data.data.vehicule.autoMotoPictureCode !== null) {
            this.allImage.vehicule =
              `https://rmobility.ovh/admin/image/?codeImage=` +
              data.data.vehicule.autoMotoPictureCode +
              `&token=` +
              this.tokenUser;

            this.actualImageSrc =
              `https://rmobility.ovh/admin/image/?codeImage=` +
              data.data.vehicule.autoMotoPictureCode +
              `&token=` +
              this.tokenUser;
          } else {
            this.allImage.vehicule = require("@/assets/images/users/attente.png");
            this.actualImageSrc = require("@/assets/images/users/attente.png");
          }
        } else {
          this.allImage.vehicule = require("@/assets/images/users/attente.png");
        }
      }
      const dataAutomoto = await apiRequest(
        "GET",
        "admin/rmo/particulier/stat?id=" + this.idUser,
        undefined,
        false
      );
      if (dataAutomoto && dataAutomoto.data) {
        (this.autopartageStat.nombreLocation =
          dataAutomoto.data.nombreLocation),
          (this.autopartageStat.gain = dataAutomoto.data.gain),
          (this.autopartageStat.nombreAnnuler =
            dataAutomoto.data.nombreAnnuler);
      }

      const dataActivity = await apiRequest(
        "GET",
        "admin/trajets/activites/?id=" + this.idUser,
        undefined,
        false
      );

      if (dataActivity && dataActivity.data) {
        this.activity.publies = dataActivity.data.conducteur.publie
          ? dataActivity.data.conducteur.publie
          : "0";
        this.activity.annulees = dataActivity.data.conducteur.annules
          ? dataActivity.data.conducteur.annules
          : "0";
        this.activity.a_venir = dataActivity.data.conducteur.avenir
          ? dataActivity.data.conducteur.avenir
          : "0";
        this.activity.effectues = dataActivity.data.conducteur.effectues
          ? dataActivity.data.conducteur.effectues
          : "0";
        this.activity.gains = dataActivity.data.conducteur.gains
          ? dataActivity.data.conducteur.gains
          : "0";
        this.activity.reservations_effectuees = dataActivity.data.passager
          .reserEffectues
          ? dataActivity.data.passager.reserEffectues
          : "0";
        this.activity.reservations_annulees = dataActivity.data.passager
          .reserAnnules
          ? dataActivity.data.passager.reserAnnules
          : "0";
        this.activity.voyages_effectues = dataActivity.data.passager
          .voyagesEffectues
          ? dataActivity.data.passager.voyagesEffectues
          : "0";
      }

      const avisData = await apiRequest(
        "GET",
        "admin/avis/?id=" + this.idUser,
        undefined,
        false
      );

      if (avisData && avisData.data) {
       
        this.avislaisses = avisData.data.avisEmis;
        this.avisrecus = avisData.data.avisRecu;
      }

      const historiqueAdmin = await apiRequest(
        "GET",
        "admin/trajets/historique/?id=" + this.idUser,
        undefined,
        false
      );

      if (
        historiqueAdmin &&
        historiqueAdmin.data &&
        historiqueAdmin.data.conducteur
      ) {
        const historiqueConducteurTable = historiqueAdmin.data.conducteur.map(
          (historique) => {
            var passagers = "";
            historique.passagers.forEach((element) => {
              if (element.statut == "ACTIF") {
                passagers +=
                  "-" + element.firstname + " " + element.lastname + "\n";
              }
            });
            return {
              trajet: historique.lieuDepart + " >> " + historique.lieuArrivee,
              date: format(
                new Date(historique.dateHeureDepart),
                "dd-MM-yyyy hh:mm:ss"
              ),
              prix: historique.prix,
              statut: historique.statutTrajet,
              passagers: passagers,
            };
          }
        );
        this.conducteurData = historiqueConducteurTable;
      }

      if (
        historiqueAdmin &&
        historiqueAdmin.data &&
        historiqueAdmin.data.passager
      ) {
        const historiquePassagerTable = historiqueAdmin.data.passager.map(
          (historique) => {
            var passagers = "";
            historique.passagers.forEach((element) => {
              if (element.statut == "ACTIF") {
                passagers +=
                  "-" + element.firstname + " " + element.lastname + "\n";
              }
            });
            return {
              trajet: historique.lieuDepart + " >> " + historique.lieuArrivee,
              date: format(
                new Date(historique.dateHeureDepart),
                "dd-MM-yyyy hh:mm:ss"
              ),
              prix: historique.prix,
              statut: historique.statutTrajet,
              passagers: passagers,
            };
          }
        );
        this.passagerData = historiquePassagerTable;
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    verification: function () {
     
    },
    ValidationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    Submit: function () {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    toogleShowPassword() {
      var show = document.getElementById("password");
      if (this.showPassword == false) {
        this.showPassword = true;
        show.type = "text";
      } else {
        this.showPassword = false;
        show.type = "password";
      }
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    async loadUserGain() {
      const activUser = await apiRequest(
        "GET",
        "gain-parrain/gain-by-user?id="+this.idUser,
        undefined,
        false
      );

      if (activUser && activUser.data) {

    this.gainCurrentUser.attente=activUser.data.gainAttente
    this.gainCurrentUser.approuve=activUser.data.gainPercu
    this.gainCurrentUser.rejete=activUser.data.gainRejecte
    this.gainCurrentUser.total=activUser.data.totalGain

       
      }
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;

      this.$v.$touch();
    },

    setDepartContry: function (place) {
      //console.log('place:', place)
      if (place) {
        //console.log("place:", place);
        /*console.log('place.formatted_address:', place.formatted_address)*/
        this.user.villeHabite = place.formatted_address;
        this.user.latitude = place.geometry.location.lat();
        this.user.longitude = place.geometry.location.lng();
  
      }
    },

    setDepartContryWorkPlace: function (place) {
 
      if (place) {

        this.user.villeresidence = place.formatted_address;
        this.user.lngWorkPlace = place.geometry.location.lng();
        this.user.latWorkPlace = place.geometry.location.lat();
  
      }
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    showSingle() {
      this.imgs = require("@/assets/images/logo.png");
      this.show();
    },
    showImage(img) {
      this.imgs = img.srcElement.src;
      this.show();
    },
    show() {
      this.visible = true;
    },

    handleFileProfil() {
      this.fileProfil = this.$refs.fileProfil.files[0];
    },
    handleFilePermis() {
      this.filePermis = this.$refs.filePermis.files[0];
    },
    handleFileMoto() {
      this.fileMoto = this.$refs.fileMoto.files[0];
    },
    handleFileCni() {
      this.fileCni = this.$refs.fileCni.files[0];
    },

    async verifyPictureRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPictureAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyCniRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyCniAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPermisRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPermisAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyVehiculeRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyVehiculeAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyAvisRefuse(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    async verifyAvisAccepte(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    async desactiverUser() {
      const desacUser = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.idUser },
        false
      );

      if (desacUser && desacUser.data) {
        this.init();
        //console.log("Test:",this.userDesac)
      }
    },
    async ActiverUser() {
      const activUser = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.idUser },
        false
      );

      if (activUser && activUser.data) {
        this.init();
      }
    },
    async attribuerBadge() {
      const badge = await apiRequest(
        "POST",
        "admin/valider-user",
        {
          idUser: this.idUser,
          attribuer: true,
        },
        false
      );
      if (badge && badge.data) {
        this.init();
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
    },
    async retirerBadge() {
      const retirer = await apiRequest(
        "POST",
        "admin/valider-user",
        {
          idUser: this.idUser,
          attribuer: false,
        },
        false
      );
      if (retirer && retirer.data) {
        this.init();
        this.showretire = true;
      } else {
        this.showEchec = true;
      }
    },
    async addCNI() {
      this.showModalcni = false;
      if (this.fileCni) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("cni", this.fileCni);

        const returnFilecni = await apiRequest(
          "POST",
          "admin/user-cni-pic",
          formData,
          true
        );

        if (returnFilecni && returnFilecni.data) {
          this.init();
         
         
        }
      }
    },
    async addProfil() {
      this.showModalprofil = false;
      if (this.fileProfil) {
        let formData = new FormData();
        formData.append("id", this.idUser);
        formData.append("avatar", this.fileProfil);

        const returnFileProfil = await apiRequest(
          "POST",
          "admin/user-pic",
          formData,
          true
        );

        if (returnFileProfil && returnFileProfil.data) {
         
          this.init();
        }
      }
    },
    async addPermis() {
      this.showModalpermis = false;
      if (this.filePermis) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.filePermis);

        const returnFilePermis = await apiRequest(
          "POST",
          "admin/user-permis-pic",
          formData,
          true
        );

        if (returnFilePermis && returnFilePermis.data) {
         
          this.init();
        }
      }
    },
    async addAutoMoto() {
      this.showModalautoMoto = false;
      if (this.fileMoto) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.fileMoto);

        const returnFileAutoMoto = await apiRequest(
          "POST",
          "admin/user-automoto-pic",
          formData,
          true
        );

        if (returnFileAutoMoto && returnFileAutoMoto.data) {
         
          this.init();
        }
      }
    },
    messageShow(id, typeMessage) {
      this.messageSelect = typeMessage;
      //console.log('typeMessage:', typeMessage)
      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      this.messageContent.userId = id;
      //console.log(row.id);
    },
    async addMessage() {
      this.showMessage = false;
      this.showMessageMail = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.showSucessM = true;
        this.init();
       
      } else {
        this.showEchecM = true;
        this.init();
       
      }
    },
    async changePass() {
      this.showModalChangePass = false;
     

      const { newpassword, confnewpassword } = this;

      if (
        newpassword == "" ||
        confnewpassword == "" ||
        newpassword != confnewpassword
      ) {
        this.showEchecChange = true;
      } else {
        const data = await apiRequest(
          "POST",
          "admin/user/change-pass",
          {
            idUser: this.idUser,
            password: newpassword,
          },
          false
        );
        if (data && data.data) {
          this.showChange = true;
        } else {
          this.showEchecChange = true;
        }
      }
    },
    updateAutomoto() {
      this.showUpdate = true;
     
      this.location.boite= this.AutoMoto.selectedModel!=null?this.AutoMoto.selectedModel :this.location.boite

    },
    async changeAutomoto() {
      const data = await apiRequest(
        "POST",
        "admin/rmo/vehicule/update",
        {
          id: this.idUser,
          idVehicule: this.idVehicule,
          autoMotoName: this.AutoMoto.nomAuto,
          autoMotoImmatriculation: this.AutoMoto.immatriculation,
          autoMotoModel: this.AutoMoto.model,
          autoMotoCouleur: this.AutoMoto.couleur,
          autoMotoMotorisation: this.AutoMoto.motorisation,
          autoMotoAnneeMiseEnCirculation: this.AutoMoto.anneeCirculation,
          aClimatisation: this.AutoMoto.clime,
          nombreSieges: this.AutoMoto.nombresiege,
          autoMotoNumeroCarteGrise: this.AutoMoto.cartegrise,
          prixPublieJournalierHorsVille: parseInt(
            this.location.prixJournalierHorsVille
          ),
          compagnieAssurance: this.location.compagnieAssurance,
          numeroAssurance: this.AutoMoto.numeroAssurance,
          prixPublieJournalier: parseInt(this.location.prixJournalier),
          boite: this.location.boite,
          lieu: this.AutoMoto.lieu,
        },
        false
      );

      if (data && data.data) {
        this.$toast.success("Information location changé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      } else {
        this.$toast.error(
          "Information location non modifiée. Veuillez réessayer",
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
      this.showUpdate = false;
      this.idVehicule = 0;
    },
    async changeName() {
      this.showModalChangeName = false;
      //console.log("idUser:", this.idUser);
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          lastname: this.lastname,
          firstname: this.firstname,
        },
        false
      );

      if (edit && edit.data) {
        this.position();
        this.init();
      } else {
        this.positionOff();
      } /**/
    },
    async changeTel() {
      this.showModalChangeTel = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          indicatifTel: this.indicatif,
          tel: this.tel,
        },
        false
      );

      if (edit && edit.data) {
        this.$toast.success(
          `Le numéro de l'utilisateur a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        this.init();
      }
    },
    position() {
      Vue.swal({
        position: "center",
        icon: "success",
        title: "Effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    positionOff() {
      Vue.swal({
        position: "center",
        icon: "error",
        title: "Erreur. Réessayer.",
        showConfirmButton: false,
        timer: 3000,
      });
    },

    getHistorique() {
      const data = apiRequest(
        "GET",
        "admin/rmo/particulier/autopartage?id=" + this.idUser,
        undefined,
        false
      );

      if (data && data.data) {
        const formattedTable = data.data.reservations.map(
          (historiqueAutopartage) => {
            return {
              id: historiqueAutopartage.id,
              model: historiqueAutopartage.model,
              immatricule: historiqueAutopartage.immatricule,
              marque: historiqueAutopartage.marque,
              assurance: historiqueAutopartage.assurance,
              montant: historiqueAutopartage.montant,
              statut: historiqueAutopartage.statut,
            };
          }
        );
        this.autopartageData = formattedTable;
        this.totalAutoPartage = formattedTable.length;
      }
    },
    CloseModalProfil() {
      this.showModalprofil = false;
    },

    refuseDialog(infoVehicule) {
      this.otherLocationList.forEach((data) => {
        if (data.autoMoto == infoVehicule.id) {
         
          this.userToUse = data.id;
        }
      });

      this.showDelete = true;
    },

    async refuser() {
      const data = await apiRequest(
        "POST",
        "admin/rmo/particulier/action",
        {
          idVehicule: this.userToUse,
          actif: false,
        },
        false
      );

      if (data && data.data) {
        this.showDelete = false;

        this.$toast.success("Véhicule refusé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },

    /** Other auto moto info */

    loadCurrentVehiculeInfo(infoVehicule) {
      
      console.log('info current car')
      console.log('info current car')
      console.log('info current car')
      console.log('info current car')
      console.log('info current car')
      console.log('info current car')
      console.log('info current car',infoVehicule)
     
      this.actualImageSrc = infoVehicule.picture;

      this.otherLocationList.forEach((data) => {
        if (data.autoMoto == infoVehicule.id) {
         
          this.location.boite = data.boite;
          this.location.lieu = data.lieu;
          this.location.numAssurance = data.numeroAssurance;
          this.location.model = data.model;
          this.location.prixJournalier = data.prixPublieJournalier;
          this.location.prixJournalierHorsVille =
            data.prixPublieJournalierHorsVille;
          this.location.compagnieAssurance = data.compagnieAssurance;
          this.idVehicule = 0;
        }
      });

      this.idVehicule = infoVehicule.id;
      this.idUser = 0;
      this.AutoMoto.model = infoVehicule.autoMotoModel;
      this.AutoMoto.id = infoVehicule.id;
      this.AutoMoto.couleur = infoVehicule.autoMotoCouleur;
      this.AutoMoto.immatriculation = infoVehicule.autoMotoImmatriculation;
      this.AutoMoto.motorisation = infoVehicule.autoMotoMotorisation;
      this.AutoMoto.nombresiege = infoVehicule.nombreSieges;
      this.AutoMoto.anneeCirculation =
        infoVehicule.autoMotoAnneeMiseEnCirculation;
      this.AutoMoto.categorie = infoVehicule.autoMotoCategorie;
      this.AutoMoto.climatisation =
        infoVehicule.aClimatisation == true ? "Oui" : "Non";

      this.AutoMoto.clime = infoVehicule.aClimatisation;
      this.AutoMoto.nomAuto = infoVehicule.autoMotoName;
      this.AutoMoto.cartegrise = infoVehicule.autoMotoNumeroCarteGrise;
      this.AutoMoto.selectedModel = infoVehicule.boite;

     
        
    },

    loadCurrentImageInfo() {
      this.init();
      this.idVehicule = 0;
    },

    imageProfileGet(imageVehicule) {
     

      if (imageVehicule != null) {
        return imageVehicule;
      } else {
        return require("@/assets/images/users/attente.png");
      }
    },

    async changeEmail() {
      this.showModalChangeEmail = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          email: this.user.email,
        },
        false
      );

      if (edit && edit.data) {
        this.$toast.success(`L'adresse email a été modifié avec succès !!!`, {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        this.init();
      } else {
        this.$toast.error("La modification du mail a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    async changePersoneUrgence() {
      this.showModalChangeUrgence = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          personneUrgence: this.user.personneUrgence,
          indicatifContactTel: this.user.indicatifContactTel,
          contactUrgence: this.user.contactUrgence,
        },
        false
      );

      if (edit && edit.data) {
        this.$toast.success(
          `Information modifiée avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );

        this.init();
      } else {
        this.$toast.error("La modification a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    async changeLieuTravail() {
      this.showModalChangeLieuTravail = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          adresse: this.user.villeHabite,
          longitude:     this.user.longitude,
          latitude:      this.user.latitude
        },
        false
      );

      if (edit && edit.data) {
        this.$toast.success(
          `La ville de résidence a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );

        this.init();
      } else {
        this.$toast.error(
          "La modification de la ville de résidence a échouée",
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
    },


    async changeWorkspace() {
      this.showModalWorkspace = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          workPlaceName: this.user.villeresidence,
          latWorkPlace:  this.user.latWorkPlace,
          lngWorkPlace:  this.user.lngWorkPlace,
         
        },
        false
      );

      if (edit && edit.data) {
        this.$toast.success(
          `Le lieu de travail a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );

        this.init();
      } else {
        this.$toast.error("La modification du lieu de travail a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async changeApropo() {
      this.showModalChangeApropo = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          apropos: this.user.apropos,
        },
        false
      );

      if (edit && edit.data) {
        this.$toast.success(
          `Information modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );

        this.init();
      } else {
        this.$toast.error("La modification de l'information a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <!-- <div class="col-md-3 desImages" style="text-align: center">
                <a class="image-popup-vertical-fit ml-5" @click="showImage">
                  <img
                    style="cursor: pointer"
                    class="avatar-xl"
                    :src="`${this.allImage.avatar}`"
                    data-holder-rendered="true"
                  />
                </a>
                <vue-easy-lightbox
                  :visible="vue"
                  :imgs="img"
                  @hide="handleHide"
                ></vue-easy-lightbox>
                <div class="">
                  <div class="mt-2">
                    {{ user.username }}
                    <a
                      href="javascript:void(0);"
                      class="text-primary"
                      v-b-tooltip.hover
                      @click="showModalChangeName = true"
                      title="Editer"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                  </div>
                  <div class="mt-2">
                    <i class="mdi mdi-phone mr-1"></i>
                    <a
                      :href="'https://wa.me/' + user.tel"
                      class="text-success"
                      v-b-tooltip.hover
                      title="Envoyer un message whatsapp"
                      target="_blank"
                    >
                      {{ user.tel }}
                    </a>
                  </div>
                  <div v-if="user.levelRate < 50">
                    <star-rating
                      class="ml-3 mt-2"
                      :rating="1"
                      :read-only="true"
                      :star-size="28"
                      :border-width="2"
                      border-color="#d8d8d8"
                      :rounded-corners="true"
                      :max-rating="3"
                      :show-rating="false"
                    ></star-rating
                    ><br />
                    <span
                      style="font-weight: bold; color: black"
                      class="ml-2"
                      >{{ user.level }}</span
                    >
                  </div>
                  <div v-else-if="user.levelRate < 99">
                    <star-rating
                      class="ml-3 mt-2"
                      :rating="2"
                      :read-only="true"
                      :star-size="28"
                      :border-width="2"
                      border-color="#d8d8d8"
                      :rounded-corners="true"
                      :max-rating="3"
                      :show-rating="false"
                    ></star-rating
                    ><span
                      style="font-weight: bold; color: black"
                      class="ml-2"
                      >{{ user.level }}</span
                    >
                  </div>
                  <div v-else>
                    <star-rating
                      class="ml-3 mt-2"
                      :rating="3"
                      :read-only="true"
                      :star-size="28"
                      :border-width="2"
                      border-color="#d8d8d8"
                      :rounded-corners="true"
                      :max-rating="3"
                      :show-rating="false"
                    ></star-rating
                    ><span
                      style="font-weight: bold; color: black"
                      class="ml-2"
                      >{{ user.level }}</span
                    >
                  </div>
                </div>
                <div class="ml-2">
                  <div class="mt-2">
                    <div>
                      <i class="text-warning mdi mdi-star"></i
                      >{{ user.rating }}/5 - {{ user.nombreVote }} avis recus
                    </div>
                    <div class="ml-3 mt-2">
                      <i class="mdi mdi-car mr-1"></i>{{ user.usertype }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <i class="mdi mdi-gmail mr-1"></i>{{ user.email }}
                  </div>
                </div>
                <div class="mt-2">
                  En cas d'urgence <br />
                  {{ user.personneUrgence }} : {{ user.indicatifContactTel
                  }}{{ user.contactUrgence }}
                </div>
              </div>
              <div class="d-flex">
                <div class="col-md-6 flexibleAtitude">
                  <div v-if="this.verify.email === true" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    E-mail vérifié
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    E-mail non vérifié
                  </div>

                  <div v-if="this.verify.permis === true" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    Permis vérifié
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Permis non vérifié
                  </div>
                  <div v-if="this.verify.autoMoto === true" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    Véhicule vérifié
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Véhicule non vérifié
                  </div>
                  <div v-if="this.verify.image === true" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    Photo de profil vérifié
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Photo de profil non vérifié
                  </div>
                  <div v-if="this.verify.tel === true" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    Numéro de téléphone vérifié
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Numéro de téléphone non vérifié
                  </div>
                  <div v-if="this.verify.cni === true" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    Pièce d'identité vérifiée
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Pièce d'identité non vérifiée
                  </div>
                </div> 
                </div>

                <div class="col-md-6 flexibleAtitude">
                  <div
                    v-if="this.preference.acceptHandicape == 'NEVER'"
                    class="mt-4"
                  >
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Je n'accepte pas les PMR
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    J'accepte les PMR
                  </div>

                  <div
                    v-if="this.preference.discutIncar == 'NEVER'"
                    class="mt-4"
                  >
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Je n'aime pas discuter en voiture
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />J'aime discuter en voiture
                  </div>
                  <div v-if="this.preference.eatIncar == 'NEVER'" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Je n'aime pas manger en voiture
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    J'aime manger en voiture
                  </div>
                  <div
                    v-if="this.preference.musicIncar == 'NEVER'"
                    class="mt-4"
                  >
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Je n'écoute pas la musique en voiture
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    J'écoute la musique en voiture
                  </div>
                  <div v-if="this.preference.smoke == 'NEVER'" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Je n'aime pas fumer en voiture
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    J'aime fumer en voiture
                  </div>
                  <div v-if="this.preference.stop == 'NEVER'" class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/multiply.svg"
                      width="30"
                    />
                    Je ne tolère pas les arrêts
                  </div>
                  <div v-else class="mt-4">
                    <img
                      class="mr-2"
                      alt
                      src="@/assets/images/verification/correct.svg"
                      width="30"
                    />
                    Je tolère les arrêts
                  </div>
                </div>
              </div>

              <div class="btnFlex float-right" style="margin-top: 50px">
                <div>
                  <button
                    title="Solde Wallet"
                    style="background: maroon; color: white"
                    class="btn mb-2 mr-2"
                    disabled
                  >
                    <i class="mdi mdi-wallet align-middle mr-2"></i>Solde :
                    {{ user.solde }} XOF
                  </button>
                </div>
                <div v-if="this.user.active == true">
                  <button
                    type="submit"
                    @click="desactiverUser"
                    class="btn btn-warning mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Désactiver ce
                    compte
                  </button>
                </div>
                <div v-else>
                  <button
                    type="submit"
                    @click="ActiverUser"
                    class="btn btn-info mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Activer ce compte
                  </button>
                </div>
                <button type="submit" class="btn btn-danger mb-2 mr-2">
                  <i class="ri-close-line align-middle mr-2"></i>Supprimer ce
                  compte
                </button>

                <div v-if="this.user.badge == true">
                  <button
                    type="submit"
                    @click="retirerBadge"
                    class="btn btn-info mb-2 mr-2"
                  >
                    <i class="mdi mdi-certificate align-middle mr-2"></i>Retirer
                    le badge
                  </button>
                </div>
                <div v-else>
                  <button
                    type="submit"
                    @click="attribuerBadge"
                    class="btn btn-success mb-2 mr-2"
                  >
                    <i class="mdi mdi-certificate align-middle mr-2"></i
                    >Attribuer un badge
                  </button>
                </div>
                <div style="text-align: left">
                  <button
                    style="background: orange; color: black"
                    type="submit"
                    @click="
                      messageShow(idUser, {
                        name: 'Notification',
                        value: 0,
                      })
                    "
                    title="Notification"
                    class="btn mb-2 mr-2"
                  >
                    <i class="fas fa-bell align-middle"></i>
                  </button>

                  <button
                    style="background: cyan; color: black"
                    type="submit"
                    @click="messageShow(idUser, { name: 'Mail', value: 1 })"
                    title="Mail"
                    class="btn mb-2 mr-2"
                  >
                    <i class="fas fa-envelope align-middle"></i>
                  </button>

                  <button
                    style="background: red; color: black"
                    type="submit"
                    @click="showModalChangePass = true"
                    title="Modification mot de passe"
                    class="btn mb-2 mr-2"
                  >
                    <i class="fas fa-key align-middle"></i>
                  </button>
                </div>
              </div> -->
            
              <div class="col-md-3 firstColonne" style="text-align: center">
              
              <div class="">
               <!--  <div class="mt-2 text-center">
                  <span class="username"> {{ user.username }}</span>
                  <a
                    href="javascript:void(0);"
                    class="text-primary"
                    v-b-tooltip.hover
                    @click="showModalChangeName = true"
                    title="Editer"
                  >
                    <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                  </a>
                </div>

                <div class="text-center">
                  <span class="user-grade"> {{ user.level }} </span>
                </div> -->
                <!-- 
                <div class="mt-2 text-center">
                  <i class="mdi mdi-phone iconForm text-success"></i>
                  <a
                    :href="'https://wa.me/' + user.tel"
                    class="text-success"
                    v-b-tooltip.hover
                    title="Envoyer un message whatsapp"
                    target="_blank"
                  >
                    {{ user.tel }}
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="text-primary"
                    v-b-tooltip.hover
                    @click="showModalChangeTel = true"
                    title="Modifier"
                  >
                    <i class="mdi mdi-pencil iconForm"></i>
                  </a>
                </div>
                <div v-if="user.levelRate < 50" class="flexibleStart">
                  <star-rating
                    class="mt-1 text-center"
                    :rating="1"
                    :read-only="true"
                    :star-size="22"
                    :border-width="2"
                    border-color="#d8d8d8"
                    :rounded-corners="true"
                    :max-rating="3"
                    :show-rating="false"
                  ></star-rating
                  ><br />
                  <span style="font-weight: bold; color: black">{{
                    user.level
                  }}</span>
                </div>
                <div v-else-if="user.levelRate < 99" class="flexibleStart">
                  <star-rating
                    class="mt-1"
                    :rating="2"
                    :read-only="true"
                    :star-size="28"
                    :border-width="2"
                    border-color="#d8d8d8"
                    :rounded-corners="true"
                    :max-rating="3"
                    :show-rating="false"
                  ></star-rating
                  ><span style="font-weight: bold; color: black">{{
                    user.level
                  }}</span>
                </div>
                <div v-else class="flexibleStart">
                  <star-rating
                    class="mt-1"
                    :rating="3"
                    :read-only="true"
                    :star-size="28"
                    :border-width="2"
                    border-color="#d8d8d8"
                    :rounded-corners="true"
                    :max-rating="3"
                    :show-rating="false"
                  ></star-rating
                  ><span style="font-weight: bold; color: black">{{
                    user.level
                  }}</span>
                </div> -->
              </div>
            <div class="container-detail-profi">
                <div class="card-adresse">
                  <div class="icon">
                    <i class="fa fa-mobile" aria-hidden="true"></i>
                  </div>
                  <div class="detail">
                    <div class="user-tel-title">Téléphone</div>
                    <div class="user-tel-phone">
                      <a
                        :href="'https://wa.me/' + user.tel"
                        class="link-color-whatsap"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ user.tel }}
                      </a>

                      <a
                        href="javascript:void(0);"
                        class="link-color-whatsap"
                        v-b-tooltip.hover
                        @click="showModalChangeTel = true"
                        title="Editer"
                      >
                        <i
                          class="mdi mdi-pencil iconForm text-color-profil"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card-adresse">
                  <div class="icon"><i class="fa fa-envelope-open"></i></div>
                  <div class="detail">
                    <div class="user-tel-title">Email</div>
                    <div class="user-tel-phone">
                      {{ user.email }}
                      <a
                        href="javascript:void(0);"
                        class="link-color-whatsap"
                        v-b-tooltip.hover
                        @click="showModalChangeEmail = true"
                        title="Editer"
                      >
                        <i
                          class="mdi mdi-pencil iconForm text-color-profil"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card-adresse">
                  <div class="icon"><i class="fa fa-user-tag"></i></div>
                  <div class="detail">
                    <div class="user-tel-title">Type d'utilisateur</div>
                    <div class="user-tel-phone">{{ user.usertype }}</div>
                  </div>
                </div>

                <div class="card-adresse">
                  <div class="icon"><i class="fa fa-signal"></i></div>
                  <div class="detail">
                    <div class="user-tel-title">Nombre d'avis</div>
                    <div class="user-tel-phone">{{ user.nombreVote }}</div>
                  </div>
                </div>
                <div class="card-adresse">
                  <div class="icon"><i class="fa fa-star"></i></div>
                  <div class="detail telspace">
                    <div class="user-tel-title">Nombre d'étoiles</div>
                    <div class="user-tel-phone">
                      <star-rating
                        class="mt-1 taille-rate"
                        :rating="user.rating"
                        :read-only="true"
                        :star-size="28"
                        :border-width="2"
                        border-color="#d8d8d8"
                        :rounded-corners="true"
                        :max-rating="5"
                        :show-rating="false"
                      ></star-rating>
                      {{ user.rating }}/5
                    </div>
                  </div>
                </div>

                <div class="card-adresse">
                  <div class="icon"><i class="fa fa-user-nurse"></i></div>
                  <div class="detail telspace">
                    <div class="user-tel-title">En cas d'urgence</div>
                    <div class="user-tel-phone">
                      {{ user.personneUrgence }} - 
                    
                      {{ user.indicatifContactTel }}{{ user.contactUrgence }}

                      <a
                        href="javascript:void(0);"
                        class="link-color-whatsap"
                        v-b-tooltip.hover
                        @click="showModalChangeUrgence = true"
                        title="Editer"
                      >
                        <i
                          class="mdi mdi-pencil iconForm text-color-profil"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card-adresse">
                  <div class="icon">
                    <i class="fa-solid fa-house-laptop"></i>
                  </div>
                  <div class="detail telspace">
                    <div class="user-tel-title">Lieu de travail</div>
                    <div class="user-tel-phone">
                      {{ user.villeresidence }}

                      <a
                        href="javascript:void(0);"
                        class="link-color-whatsap"
                        v-b-tooltip.hover
                        @click="showModalWorkspace = true"
                        title="Editer"
                      >
                        <i
                          class="mdi mdi-pencil iconForm text-color-profil"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card-adresse">
                  <div class="icon"><i class="fa-solid fa-city"></i></div>
                  <div class="detail telspace">
                    <div class="user-tel-title">Ville de résidence</div>
                    <div class="user-tel-phone">
                      {{ user.villeHabite }}

                      <a
                        href="javascript:void(0);"
                        class="link-color-whatsap"
                        v-b-tooltip.hover
                        @click="showModalChangeLieuTravail = true"
                        title="Editer"
                      >
                        <i
                          class="mdi mdi-pencil iconForm text-color-profil"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card-adresse">
                  <div class="icon">
                    <i class="fa-solid fa-address-card"></i>
                  </div>
                  <div class="detail telspace">
                    <div class="user-tel-title">À propos</div>
                    <div class="user-tel-phone">
                      {{ user.apropos }}

                      <a
                        href="javascript:void(0);"
                        class="link-color-whatsap"
                        v-b-tooltip.hover
                        @click="showModalChangeApropo = true"
                        title="Editer"
                      >
                        <i
                          class="mdi mdi-pencil iconForm text-color-profil"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row flexible-gain-status" >
                <div class="gain-status">
                 <h3>Gain en attente</h3> 
                  <p>{{ gainCurrentUser.attente }}</p>
                </div>

                <div class="gain-status">
                  <h3> Gain approuvé</h3>
                  <p>{{ gainCurrentUser.approuve }}</p>
                </div>

                <div class="gain-status">
                  <h3> Gain rejeté </h3>
                  <p>{{ gainCurrentUser.rejete }}</p>
                </div>

                <div class="gain-status">
                  <h4>  Gain total</h4>
                  <p>{{ gainCurrentUser.total }}</p>
                </div>



              </div>
              <div class="row">
                <div class="col-md-12 d-flex justify-content-between">
                  <div class="col-md-6">
                    <div
                      v-if="this.verify.email === true"
                      class=" flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      E-mail vérifié
                    </div>
                    <div v-else class=" flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      E-mail non vérifié
                    </div>

                    <div
                      v-if="this.verify.permis === true"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      Permis vérifié
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Permis non vérifié
                    </div>
                    <div
                      v-if="this.verify.autoMoto === true"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      Véhicule vérifié
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Véhicule non vérifié
                    </div>
                    <div
                      v-if="this.verify.image === true"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      Photo de profil vérifié
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Photo de profil non vérifié
                    </div>
                    <div
                      v-if="this.verify.tel === true"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      Numéro de téléphone vérifié
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Numéro de téléphone non vérifié
                    </div>
                    <div
                      v-if="this.verify.cni === true"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      Pièce d'identité vérifiée
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Pièce d'identité non vérifiée
                    </div>
                    <!-- </div> -->
                  </div>

                  <div class="col-md-6 margiN">
               
                  <div
                      v-if="this.preference.acceptHandicape == 'NEVER'"
                      class=" flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Je n'accepte pas les PMR
                    </div>
                    <div v-else class="flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      J'accepte les PMR
                    </div>

                    <div
                      v-if="this.preference.discutIncar == 'NEVER'"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Je n'aime pas discuter en voiture
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>

                      J'aime discuter en voiture
                    </div>
                    <div
                      v-if="this.preference.eatIncar == 'NEVER'"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Je n'aime pas manger en voiture
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      J'aime manger en voiture
                    </div>
                    <div
                      v-if="this.preference.musicIncar == 'NEVER'"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Je n'écoute pas la musique en voiture
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      J'écoute la musique en voiture
                    </div>
                    <div
                      v-if="this.preference.smoke == 'NEVER'"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Je n'aime pas fumer en voiture
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      J'aime fumer en voiture
                    </div>
                    <div
                      v-if="this.preference.stop == 'NEVER'"
                      class="mt-4 flexibleLine"
                    >
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/multiply.svg"
                          width="30"
                        />
                      </div>
                      Je ne tolère pas les arrêts
                    </div>
                    <div v-else class="mt-4 flexibleLine">
                      <div class="imageContainer">
                        <img
                          class="mr-2"
                          alt
                          src="@/assets/images/verification/correct.svg"
                          width="30"
                        />
                      </div>
                      Je tolère les arrêts
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            
            </div>
            <div
              class="col-md-2 ml-5 float-right buttonList"
              style="margin-top: 10px"
            >
              <div class="flexibleImage">
                <a class="image-popup-vertical-fit marginImage" @click="showImage">
                <img
                  style="cursor: pointer"
                  class="avatar-xl imageProfile"
                  :src="`${this.allImage.avatar}`"
                  data-holder-rendered="true"
                />
              </a>
              <vue-easy-lightbox
                :visible="vue"
                :imgs="img"
                @hide="handleHide"
              ></vue-easy-lightbox> 
              <div class="mt-2 text-center">
                  <span class="username"> {{ user.username }}</span>
                  <a
                    href="javascript:void(0);"
                    class="text-primary"
                    v-b-tooltip.hover
                    @click="showModalChangeName = true"
                    title="Editer"
                  >
                    <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                  </a>
                </div>

                <div class="text-center">
                  <span class="user-grade"> {{ user.level }} </span>
                </div>
              </div>
            <!--   <div  >
                <button
                  title="Solde Wallet "
                  style="background: maroon; color: white;"
                  class="btn mb-2 mr-2 "
                  disabled
                >
                  <i class="mdi mdi-wallet align-middle mr-2"></i>Solde :
                  {{ user.solde }} XOF
                </button>
              </div> -->
          
              
  
              <div style="text-align: center" class="otherBtn">

                <button
                  title="Solde Wallet "
                  style="background: maroon; color: white;"
                  class="btn mb-2 mr-2 "
                  disabled
                >
                  <i class="mdi mdi-wallet align-middle mr-2"></i>Solde :
                  {{ user.solde }} XOF
                </button>

                <button  v-if="this.user.active == true"
                  type="submit"
                  @click="desactiverUser"
                  class="btn btn-warning mb-2 mr-2"
                >
                  <i class="mdi mdi-account-remove mr-2"></i>Désactiver ce
                  compte
                </button>
              
                <button  v-else
                  type="submit"
                  @click="ActiverUser"
                  class="btn btn-info mb-2 mr-2"
                >
                  <i class="mdi mdi-account-remove mr-2"></i>Activer ce compte
                </button>
           
            
                <button
                  type="submit"
                  class="btn btn-danger mb-2 mr-2 supprBtn"
                >
                  <i class="ri-close-line align-middle mr-2"></i>Supprimer ce
                  compte
                </button>
           
                <button v-if="this.user.badge == true"
                  type="submit"
                  @click="retirerBadge"
                  class="btn btn-info mb-2 mr-2"
                >
                  <i class="mdi mdi-certificate align-middle mr-2"></i>Retirer
                  le badge
                </button>
        
                <button v-else
                  type="submit"
                  @click="attribuerBadge"
                  class="btn btn-success mb-2 mr-2"
                >
                  <i class="mdi mdi-certificate align-middle mr-2"></i
                  >Attribuer un badge
                </button>
                
                <button
                  style="background: orange; color: black"
                  type="submit"
                  @click="
                    messageShow(idUser, {
                      name: 'Notification',
                      value: 0,
                    })
                  "
                  title="Notification"
                  class="btn mb-2 mr-2"
                >
                  <i class="fas fa-bell align-middle"></i>Envoyer une notification
                </button>

                <button
                  style="background: cyan; color: black"
                  type="submit"
                  @click="messageShow(idUser, { name: 'Mail', value: 1 })"
                  title="Mail"
                  class="btn mb-2 mr-2"
                >
                  <i class="fas fa-envelope align-middle"></i> Envoyer un mail
                </button>

                <button
                  style="background: red; color: black"
                  type="submit"
                  @click="showModalChangePass = true"
                  title="Modification mot de passe"
                  class="btn mb-2 mr-2"
                >
                  <i class="fas fa-key align-middle"></i> Modifier mot de passe
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-body mt-3">
          <b-tabs pills justified content-class="p-3 text-muted">
            <b-tab active class="border-0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-clipboard-account mr-2"></i>Vérifications &
                  Avis</span
                >
              </template>
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <h3 class="mb-5">Vérifications</h3>

                      <div class="containerInfoProfile">
                        <div class="cards">
                          <div class="imgBx" @click="showImage">
                            <img
                              alt="profile"
                              :src="`${this.allImage.avatar}`"
                            />
                            <vue-easy-lightbox
                              :visible="visible"
                              :imgs="imgs"
                              @hide="handleHide"
                            ></vue-easy-lightbox>
                          </div>
                          <div class="contentProfile">
                            <div class="details">
                              <h2>Profil</h2>
                              <ul class="social_icons">
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="showModalprofil = true"
                                    ><i
                                      class="fa fa-upload"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="verifyPictureRefuse"
                                    id="echec"
                                    ><i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    v-if="this.verify.image == false"
                                    @click.stop.prevent="verifyPictureAccepte"
                                    id="validate"
                                    ><i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="cards">
                          <div class="imgBx" @click="showImage">
                            <img alt="profile" :src="`${this.allImage.cni}`" />
                            <vue-easy-lightbox
                              :visible="visible"
                              :imgs="imgs"
                              @hide="handleHide"
                            ></vue-easy-lightbox>
                          </div>
                          <div class="contentProfile">
                            <div class="details">
                              <h2>CNI</h2>
                              <ul class="social_icons">
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="showModalcni = true"
                                    ><i
                                      class="fa fa-upload"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="verifyCniRefuse"
                                    id="echec"
                                    ><i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    v-if="this.verify.cni == false"
                                    @click.stop.prevent="verifyCniAccepte"
                                    id="validate"
                                    ><i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="cards">
                          <div class="imgBx" @click="showImage">
                            <img
                              alt="profile"
                              :src="`${this.allImage.permis}`"
                            />
                            <vue-easy-lightbox
                              :visible="visible"
                              :imgs="imgs"
                              @hide="handleHide"
                            ></vue-easy-lightbox>
                          </div>
                          <div class="contentProfile">
                            <div class="details">
                              <h2>PERMIS</h2>
                              <ul class="social_icons">
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="showModalpermis = true"
                                    ><i
                                      class="fa fa-upload"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="verifyPermisRefuse"
                                    id="echec"
                                    ><i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    v-if="this.verify.permis == false"
                                    @click.stop.prevent="verifyPermisAccepte"
                                    id="validate"
                                    ><i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="cards">
                          <div class="imgBx" @click="showImage">
                            <img
                              alt="profile"
                              :src="`${this.allImage.vehicule}`"
                            />
                            <vue-easy-lightbox
                              :visible="visible"
                              :imgs="imgs"
                              @hide="handleHide"
                            ></vue-easy-lightbox>
                          </div>
                          <div class="contentProfile">
                            <div class="details">
                              <h2>AUTO/MOTO</h2>
                              <ul class="social_icons">
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="
                                      showModalautoMoto = true
                                    "
                                    ><i
                                      class="fa fa-upload"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="verifyVehiculeRefuse"
                                    id="echec"
                                    ><i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    v-if="this.verify.autoMoto == false"
                                    @click.stop.prevent="verifyVehiculeAccepte"
                                    id="validate"
                                    ><i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="loadCurrentImageInfo()"
                                    id="validate"
                                    ><i
                                      class="fa fa-info"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h3
                        class="textTitle"
                        v-if="otherVehiculeList.length != 0"
                      >
                        Les véhicules en location
                      </h3>
                      <div
                        class="containerInfoProfiles"
                        v-if="otherVehiculeList.length != 0"
                      >
                        <div
                          class="cards"
                          v-for="(vehicule, index) in otherVehiculeList"
                          :key="index"
                        >
                          <div class="imgBx" @click="showImage">
                            <img
                              alt="profile"
                              :src="imageProfileGet(vehicule.picture)"
                            />
                            <vue-easy-lightbox
                              :visible="visible"
                              :imgs="imgs"
                              @hide="handleHide"
                            ></vue-easy-lightbox>
                          </div>
                          <div class="contentProfile">
                            <div class="details">
                              <h2>Vehicule {{ ++index }}</h2>
                              <ul class="social_icons">
                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="refuseDialog(vehicule)"
                                    id="echec"
                                    ><i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>

                                <li>
                                  <a
                                    href="#"
                                    @click.stop.prevent="
                                      loadCurrentVehiculeInfo(vehicule)
                                    "
                                    id="validate"
                                    class="infos"
                                    ><i
                                      class="fa fa-info"
                                      aria-hidden="true"
                                    ></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-title">
                          <h3 class="mb-2">Informations Auto/Moto</h3>
                          <button
                            type="submit"
                            @click="updateAutomoto()"
                            class="btn color-rmobility mb-2 mr-2"
                          >
                            <i class="mdi mdi-pencil font-size-18 mr-2 "></i
                            >Modifier
                          </button>
                        </div>
                        <b-card
                          header-class="bg-transparent border-primary"
                          class="border border-info cardCENTER"
                        >
                          <div
                            class="row d-flex justify-content-center align-items-center"
                          >
                            <div class="col-md-4 mb-3">
                              <a
                                class="image-popup-vertical-fit"
                                @click="showImage"
                              >
                                <img
                                  class="img-fluid"
                                  alt
                                  :src="actualImageSrc"
                                  width="250"
                                  height="200"
                                />
                              </a>
                              <vue-easy-lightbox
                                :visible="visible"
                                :imgs="imgs"
                                @hide="handleHide"
                              ></vue-easy-lightbox>
                            </div>
                            <div class="col-md-4 mb-3">
                              <div>
                                <div class="theTitle">Modèle</div>
                                <div>
                                  {{ AutoMoto.model }}
                                </div>
                              </div>
                              <div class="mt-2">
                                <div class="theTitle">Marque</div>
                                <div>{{ AutoMoto.marque }}</div>
                              </div>
                              <div class="mt-2">
                                <div class="theTitle">Couleur</div>
                                <div>{{ AutoMoto.couleur }}</div>
                              </div>
                              <div class="mt-2">
                                <div class="theTitle">Immatriculation</div>
                                <div>{{ AutoMoto.immatriculation }}</div>
                              </div>
                              <div class="mt-2">
                                <div class="theTitle">Motorisation</div>
                                <div>{{ AutoMoto.motorisation }}</div>
                              </div>
                             
                              <div class="mt-2">
                                <div class="theTitle">Prix/J:</div>
                                <div>{{ location.prixJournalier }}</div>
                              </div>
                              <div class="mt-2">
                                <div class="theTitle">Prix/J Hors Ville:</div>
                                <div>
                                  {{ location.prixJournalierHorsVille }}
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 mb-3">
                              <!--<div class="mt-2">
                                    Catégorie : {{ AutoMoto.categorie }}
                                  </div>-->

                              <div class="mt-2">
                                <div class="theTitle">Lieu</div>
                                <div>{{ location.lieu }}</div>
                              </div>
                              <div>
                                <div class="theTitle">Climatisation</div>
                                <div>{{ AutoMoto.climatisation }}</div>
                              </div>
                              <div class="mt-2">
                                <div class="theTitle">Année de circulation</div>
                                <div>{{ AutoMoto.anneeCirculation }}</div>
                              </div>

                              <div class="mt-2">
                                <div class="theTitle">Nombre de sièges</div>
                                <div>{{ AutoMoto.nombresiege }}</div>
                              </div>

                              <div class="mt-2">
                                <div class="theTitle">Boîte</div>
                                <div>{{ location.boite }}</div>
                              </div>
                              <div class="mt-2">
                                <div class="theTitle">
                                  Compagnie d'assurance
                                </div>
                                <div>{{ location.compagnieAssurance }}</div>
                              </div>
                              <div class="mt-2">
                                <div class="theTitle">Numéro d'assurance</div>
                                <div>{{ location.numAssurance }}</div>
                              </div>
                            </div>
                          </div>
                        </b-card>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="card-title">
                        <h4>Avis</h4>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="card-title text-center mb-5">
                            <h5 class="bg-warning" style="font-size: 20px">
                              Avis reçus
                            </h5>
                          </div>
                          <div
                            class="media border-bottom pb-3"
                            v-for="(item, index) in avisrecus"
                            :key="index"
                          >
                            <div class="media-body">
                              <div>
                                <p class="text-muted mt-2">
                                  {{ item.avis }}
                                </p>
                                <div class="row d-flex justify-content-between">
                                  <h5 class="font-size-15 mb-3 ml-2">
                                    {{ item.lastname + " " + item.firstname }}
                                  </h5>
                                  <span
                                    class="float-sm-right mt-0 font-size-12"
                                  >
                                    {{ item.dateAvis }}
                                  </span>
                                  <div
                                    class="float-right"
                                    v-if="item.rating == 1"
                                  >
                                    <div class="row">
                                      <button
                                        @click="verifyAvisAccepte(item.id)"
                                        class="btn btn-success"
                                        v-if="item.visible == false"
                                      >
                                        <i class="fa fa-check"></i>
                                      </button>
                                      <button
                                        @click="verifyAvisRefuse(item.id)"
                                        class="btn btn-danger"
                                      >
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card-title text-left">
                            <h5
                              class="bg-success text-center mb-5"
                              style="font-size: 20px"
                            >
                              Avis laissés
                            </h5>
                          </div>
                          <div
                            class="media border-bottom pb-3"
                            v-for="(item, index) in avislaisses"
                            :key="index"
                          >
                            <div class="media-body">
                              <div>
                                <p class="text-muted mt-2 ml-3">
                                  {{ item.avis }}
                                </p>
                                <div class="row d-flex justify-content-between">
                                  <h5 class="font-size-15 mb-3 ml-4">
                                    {{ item.lastname + " " + item.firstname }}
                                  </h5>
                                  <span
                                    class="float-sm-right mt-0 font-size-12"
                                  >
                                    {{ item.dateAvis }}
                                  </span>
                                  <div
                                    class="float-right"
                                    v-if="item.rating == 1"
                                  >
                                    <div class="row">
                                      <button
                                        @click="verifyAvisAccepte(item.id)"
                                        class="btn btn-success"
                                        v-if="item.visible == false"
                                      >
                                        <i class="fa fa-check"></i>
                                      </button>
                                      <button
                                        @click="verifyAvisRefuse(item.id)"
                                        class="btn btn-danger"
                                      >
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-format-list-bulleted mr-2"></i
                  >Activités</span
                >
              </template>
              <div>
                <h2 class="text-primary">
                  En tant que
                  <span
                    style="cursor: pointer"
                    class="text-success"
                    @click="HistoriqueConducteur = true"
                    >conducteur</span
                  >
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-primary"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Trajets publiés</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-primary">{{ activity.publies }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent"
                      class="border border-success"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Trajets effectués</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-success">{{ activity.effectues }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-danger"
                      class="border border-info"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Trajets annulés</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-info">{{ activity.annulees }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-success"
                      class="border border-warning"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Trajets à venir</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-warning">{{ activity.a_venir }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-success"
                      class="blueG"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Gains</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-blueG">{{ activity.gains }} XOF</h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <h2 class="text-primary">
                  En tant que
                  <span
                    style="cursor: pointer"
                    class="text-success"
                    @click="HistoriquePassager = true"
                    >passager</span
                  >
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-primary"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Réservations éffectuées</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-primary">
                          {{ activity.reservations_effectuees }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent"
                      class="border border-success"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Voyages effectués</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-success">
                          {{ activity.voyages_effectues }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-danger"
                      class="border border-info"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Réservations annulés</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-info">
                          {{ activity.reservations_annulees }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h2 class="text-primary">
                  En location /
                  <span
                    style="cursor: pointer"
                    class="text-success"
                    @click="HistoriqueAutopartage = true"
                    >autopartage</span
                  >
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-primary"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Autopartage effectué</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-primary">
                          {{ autopartageStat.nombreLocation }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent"
                      class="border border-success"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Gain</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-success">
                          {{ autopartageStat.gain }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-danger"
                      class="border border-info"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">Autopartage annulé</h5>
                      </template>
                      <div class="float-right">
                        <h1 class="text-info">
                          {{ autopartageStat.reserveAnnuler }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalprofil"
      title="Téléverser ce profil ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileProfil"
          ref="fileProfil"
          v-on:change="handleFileProfil"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalprofil = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addProfil"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalcni"
      title="Téléverser cette CNI ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileCni"
          ref="fileCni"
          v-on:change="handleFileCni"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalcni = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addCNI"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalpermis"
      title="Téléverser ce permis ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="filePermis"
          ref="filePermis"
          v-on:change="handleFilePermis"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalpermis = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addPermis"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalautoMoto"
      title="Téléverser ce Auto/Moto ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileMoto"
          ref="fileMoto"
          v-on:change="handleFileMoto"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalautoMoto = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addAutoMoto"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      size="xl"
      hide-footer
      centered
      v-model="HistoriqueConducteur"
      title="Historique Conducteur"
      title-class="font-18"
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique des trajets</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :items="conducteurData"
              :fields="conducteur"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :prev-text="'Précédent'"
                    :next-text="'suivant'"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      size="xl"
      hide-footer
      centered
      v-model="HistoriquePassager"
      title="Historique Passager"
      title-class="font-18"
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique passagers</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :fields="fields"
              :items="passagerData"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :prev-text="'Précédent'"
                    :next-text="'suivant'"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      size="xl"
      hide-footer
      centered
      v-model="HistoriqueAutopartage"
      title="Historique d'autopartage"
      title-class="font-18"
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique d'autopartage</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :fields="autoPartage"
              :items="autopartageData"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :prev-text="'Précédent'"
                    :next-text="'suivant'"
                    :total-rows="totalAutoPartage"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessM"
      title="Message envoyé avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucessM = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Badge attribué avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecM"
      title="Echec d'envoie !!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecM = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showretire"
      title="Badge retiré avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="Badge désactivé"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>


    <b-modal
      v-model="showModalChangeUrgence"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Nom"
            v-model="user.personneUrgence"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Indicatif Tel"
            v-model="user.indicatifContactTel"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Téléphone"
            v-model="user.contactUrgence"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeName = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changePersoneUrgence">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- ENVOYER LE MESSAGE NOTIF-->
    <b-modal v-model="showMessage" title="Nouveau Message Notif" centered>
      <form>
        <div class="form-group">
          <!--<label class="control-label">Choisir le type de message:</label>-->
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
            disabled
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <!--  <ckeditor
                v-model="messageContent.message"
                :editor="editor"
                aria-placeholder="Votre Message"
              ></ckeditor> -->
          <textarea
            v-model="messageContent.message"
            placeholder="Votre message"
            class="form-control"
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- ENVOYER LE MESSAGE MAIL-->
    <b-modal v-model="showMessageMail" title="Nouveau Message Mail" centered>
      <form>
        <div class="form-group">
          <!--<label class="control-label">Choisir le type de message:</label>-->
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
            disabled
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- CHANGER LE MOT DE PASSE -->
    <b-modal
      v-model="showModalChangePass"
      title="Changer le mot de passe"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Mot de passe"
            v-model="newpassword"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Confirmation Mot de passe"
            v-model="confnewpassword"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangePass = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changePass">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showChange"
      title="Mot de passe modifié avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showChange = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecChange"
      title="Erreur Reéssayez"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecChange = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <!-- -->

    <!--UPDATE AUTOMOTO -->
    <b-modal
      size="xl"
      v-model="showUpdate"
      title="Modifier les informations AutoMoto"
      centered
    >
      <form class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Marque</label>
            <input
              type="text"
              class="form-control"
              placeholder="Marque"
              v-model="AutoMoto.nomAuto"
              required
            />
          </div>
          <div class="form-group">
            <label>Model</label>
            <input
              type="text"
              class="form-control"
              placeholder="Model"
              v-model="AutoMoto.model"
              required
            />
          </div>
          <div class="form-group">
            <label>Motorisatrion</label>
            <input
              type="text"
              class="form-control"
              placeholder="Motorisatrion"
              v-model="AutoMoto.motorisation"
              required
            />
          </div>
          <div class="form-group">
            <label>Couleur</label>
            <input
              type="text"
              class="form-control"
              placeholder="Couleur"
              v-model="AutoMoto.couleur"
              required
            />
          </div>
          <div class="form-group">
            <label class="labPaque">Boîte(Manulle/Autaumatique)</label>
            <div>
              <b-form-select
                v-model="AutoMoto.selectedModel"
                :value="options.value"
                :options="options"
              >
                <template #first> </template>
              </b-form-select>
            </div>
          </div>
          <div class="form-group">
            <label>Prix/J</label>
            <input
              type="number"
              class="form-control"
              v-model="location.prixJournalier"
              required
            />
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Nombre de sièges</label>
            <input
              type="number"
              class="form-control"
              placeholder="Nombre de sièges"
              v-model="AutoMoto.nombresiege"
              required
            />
          </div>
          <div class="form-group">
            <label>Plaque d'immatriculation</label>
            <input
              type="text"
              class="form-control"
              placeholder="Plaque d'immatriculation"
              v-model="AutoMoto.immatriculation"
              required
            />
          </div>
          <div class="form-group">
            <label>Année</label>
            <input
              type="number"
              class="form-control"
              placeholder="Année"
              v-model="AutoMoto.anneeCirculation"
              required
            />
          </div>

          <div class="form-group">
            <label>Prix/J Hors Ville</label>
            <input
              type="number"
              class="form-control"
              v-model="location.prixJournalierHorsVille"
              required
            />
          </div>
          <div class="form-group">
            <label>Compagnie d'assurance</label>
            <input
              type="text"
              class="form-control"
              v-model="location.compagnieAssurance"
              required
            />
          </div>
          <div>
            <div class="clim float-left">
              <label class="labPaque">Climatisation</label>
              <input
                type="checkbox"
                class="form-control inputCheck"
                value="AutoMoto.clime"
                v-model="AutoMoto.clime"
              />
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showUpdate = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeAutomoto">
          Modifier
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <!--UPDATE USERNAME -->
    <b-modal
      v-model="showModalChangeName"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Nom"
            v-model="lastname"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Prénoms"
            v-model="firstname"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeName = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeName">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment refuser ce véhicule ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="refuser"
          >Oui</b-button
        >
      </div>
    </b-modal>


    <!--UPDATE AUTOMOTO -->
    <!-- <b-modal
      size="xl"
      v-model="showUpdate"
      title="Modifier les informations AutoMoto"
      centered
    >
      <form class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Marque</label>
            <input
              type="text"
              class="form-control"
              placeholder="Marque"
              v-model="AutoMoto.nomAuto"
              required
            />
          </div>
          <div class="form-group">
            <label>Model</label>
            <input
              type="text"
              class="form-control"
              placeholder="Model"
              v-model="AutoMoto.model"
              required
            />
          </div>
          <div class="form-group">
            <label>Motorisatrion</label>
            <input
              type="text"
              class="form-control"
              placeholder="Motorisatrion"
              v-model="AutoMoto.motorisation"
              required
            />
          </div>
          <div class="form-group">
            <label>Couleur</label>
            <input
              type="text"
              class="form-control"
              placeholder="Couleur"
              v-model="AutoMoto.couleur"
              required
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Nombre de sièges</label>
            <input
              type="number"
              class="form-control"
              placeholder="Nombre de sièges"
              v-model="AutoMoto.nombresiege"
              required
            />
          </div>
          <div class="form-group">
            <label>Plaque d'immatriculation</label>
            <input
              type="text"
              class="form-control"
              placeholder="Plaque d'immatriculation"
              v-model="AutoMoto.immatriculation"
              required
            />
          </div>
          <div class="form-group">
            <label>Année</label>
            <input
              type="number"
              class="form-control"
              placeholder="Année"
              v-model="AutoMoto.anneeCirculation"
              required
            />
          </div>
          <div>
            <div class="clim">
              <label class="labPaque">Climatisation</label>
              <input
                type="checkbox"
                class="form-control"
                value="AutoMoto.clime"
                v-model="AutoMoto.clime"
              />
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showUpdate = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="AutoMoto.id !=null? changeAutomotoVehicule()   :changeAutomoto">
          Modifier
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
 -->
    <!--UPDATE USERNAME -->
    <b-modal
      v-model="showModalChangeName"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Nom"
            v-model="lastname"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Prénoms"
            v-model="firstname"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeName = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeName">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <!-- Personne urgence-->


    <b-modal
      v-model="showModalChangeUrgence"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Nom"
            v-model="user.personneUrgence"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Indicatif Tel"
            v-model="user.indicatifContactTel"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Téléphone"
            v-model="user.contactUrgence"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeName = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changePersoneUrgence">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!--UPDATE TELEPHONE -->
    <b-modal
      v-model="showModalChangeTel"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Indicatif"
            v-model="indicatif"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Téléphone"
            v-model="tel"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeTel = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeTel">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModalChangeEmail"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Adresse email"
            v-model="user.email"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeEmail = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeEmail">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModalChangeLieuTravail"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <gmap-autocomplete
              :value="user.villeHabite"
              type="text"
              class="form-control"
              id="depart"
              placeholder="Ville de résidence"
              @place_changed="setDepartContry"
              :select-first-on-enter="true"
              style="height: 50px"
            >
            </gmap-autocomplete>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button
          variant="secondary"
          @click="showModalChangeLieuTravail = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeLieuTravail">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModalChangeApropo"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="À propos"
            v-model="user.apropos"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeApropo = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeApropo">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModalWorkspace"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
         


          <gmap-autocomplete
              :value="user.villeresidence"
              type="text"
              class="form-control"
              id="depart"
              placeholder="Lieu de travail"
              @place_changed="setDepartContryWorkPlace"
              :select-first-on-enter="true"
              style="height: 50px"
            >
            </gmap-autocomplete>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalWorkspace = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeWorkspace">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>



    <b-modal
      v-model="showModalChangeName"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Nom"
            v-model="lastname"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Prénoms"
            v-model="firstname"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeName = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeName">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
  </Layout>
</template>

<style>
label {
  font-size: 15px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
.clim {
  position: absolute;
  float: left;
}
.inputCheck {
  position: absolute;
  left: -1.6rem;
}
.labPaque {
  display: inline-block;
}
.checkclim {
  display: flex;

  font-size: 0.9rem;
}

.theTitle {
  font-size: 16px;
  display: inline-block;
  font-weight: 700;
  text-align: left;
  color: #222;
}

@media screen and (max-width: 773px) {
  .cardCENTER {
    text-align: center;
  }

  .flexibleAtitude {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .flexibleC {
    display: flex;
    justify-content: space-evenly;
  }

  .btnFlex {
    display: flex;
  }
}

.containerInfoProfile {
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.cards {
  position: relative;
  width: 300px;
  height: 400px;
  background: linear-gradient(#2196f3, #2196f3 30%, #252b3b 30%, #252b3b);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.containerInfoProfiles {
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.containerInfoProfiles .cards {
  position: relative;
  width: 300px;
  height: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  background: linear-gradient(#252b3b, #252b3b 30%, #ffffff 30%, #2196f3);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
}
.cards {
  position: relative;
  width: 300px;
  height: 400px;
  background: linear-gradient(#2196f3, #2196f3 30%, #252b3b 30%, #252b3b);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.containerInfoProfile .cards .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 10;
  transform-origin: top;
  border-radius: 20px;
  overflow: hidden;
}

.containerInfoProfiles .cards .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 10;
  transform-origin: top;
  border-radius: 20px;
  overflow: hidden;
}

.containerInfoProfile .cards:hover .imgBx {
  transform: translateY(30px) scale(0.5);
}

.containerInfoProfiles .cards:hover .imgBx {
  transform: translateY(30px) scale(0.5);
}

.containerInfoProfile .cards .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.containerInfoProfiles .cards .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  cursor: pointer;
}

.containerInfoProfile .cards .contentProfile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 27%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  z-index: 80;
  transition: 0.5s;
  background: #252b3b;
}
.containerInfoProfiles .cards .contentProfile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 27%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  z-index: 80;
  transition: 0.5s;
  background: #252b3b;
}
.containerInfoProfile .cards:hover .contentProfile {
  transform: translateY(0);
}

.containerInfoProfiles .cards:hover .contentProfile {
  transform: translateY(0);
}

.containerInfoProfile .cards .contentProfile .details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.containerInfoProfiles .cards .contentProfile .details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.containerInfoProfile .cards .contentProfile .details h2 {
  display: inline-block;
  background: #252b3b;
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
  padding: 8px;
  border-radius: 6px;
}

.containerInfoProfiles .cards .contentProfile .details h2 {
  display: inline-block;
  background: #252b3b;
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
  padding: 8px;
  border-radius: 6px;
}

.containerInfoProfiles .cards .contentProfile .details .social_icons {
  position: relative;
  display: flex;
  margin-top: 5px;
  padding-left: 0;
}

.containerInfoProfiles .cards .contentProfile .details .social_icons li {
  list-style: none;
  margin: 4px;
  text-align: center;
}

.containerInfoProfiles .cards .contentProfile .details .social_icons li i {
  list-style: none;
  margin: auto;
}

.social_icons {
  position: relative;
  display: flex;
  margin-top: 5px;
  padding-left: 0;
}
.social_icons li {
  list-style: none;
  margin: 4px;
}

.social_icons li {
  list-style: none;
  margin: 4px;
}

.social_icons li a {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  background: #294d69;
  border-radius: 50%;
  font-size: 1.5em;
  color: #fff;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
}

.social_icons li:first-child a {
  background: rgb(30, 144, 255);
}
.social_icons li:nth-child(2) a {
  background: red;
}
.social_icons li:nth-child(3) a {
  background: rgb(36, 188, 36);
}

.social_icons li:nth-child(4) a {
  background: #ff8c00;
}

.infos {
  background: #ff8c00 !important;
}
.social_icons li i {
  width: 100%;
  margin: auto 0;
}

.social_icons li a:hover {
  background: #03a9f4;
}

.textTitle {
  line-height: 35px;
  font-weight: 700;
  font-size: 1.8em;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}


.myCard {
  background: #ffffff;
  padding: 0.385rem;
  box-shadow: 2px 2px 3px #2c3333;
  border: 0 !important;
  height: 10rem;
  transition: ease 0.3s;
}

.myCard:hover {
  background: transparent;
  padding: 0.385rem;
  border: none;
  outline: none;

  border: 0 !important;
  height: 10rem;
  backdrop-filter: blur(15px);
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12),
    inset -10px -10px 15px rgba(255, 255, 255, 0.5),
    inset 10px 10px 15px rgba(0, 0, 0, 0.12);
}
.alignBottom {
  position: absolute;
  bottom: 1.2rem;
  right: 0;
  padding-right: 1.5rem;
}

.theTitle {
  font-size: 16px;
  display: inline-block;
  font-weight: 700;
  text-align: left;
  color: #222;
}

@media screen and (max-width: 773px) {
  .cardCENTER {
    text-align: center;
  }

  .flexibleAtitude {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .flexibleC {
    display: flex;
    justify-content: space-evenly;
  }

  .btnFlex {
    display: flex;
  }
}

.ck.ck-balloon-panel {
  z-index: 1050 !important;
}

.username {
  font-size: 22px;
  text-align: center;
  font-family: Raleway, serif;
  font-weight: Bold;
  color: #0b0909;
  line-height: 35.5px;
  margin-top:2rem;
}

.user-grade {
  font-size: 16px;
  text-align: center;
  font-family: Raleway, serif;
  font-weight: 500;
  color: rgba(11, 9, 9, 0.5);
  line-height: 30px;
}

.text-color-profil {
  color: #1877f2;

  font-weight: 700;
  font-size: 1.4rem;
  margin-left: 0.4rem;
}

.container-detail-profi {
  width: 300px;
  height: auto;
  background: rgba(242, 245, 249, 1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 18px;
}
.card-adresse {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-adresse .detail {
  display: flex;
  flex-direction: column;

  padding: 0;
  margin: 0;
  flex: 100px 1;
  text-align: start;
}
.card-adresse .icon {
  width: 60px;
  height: 60px;

  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
}
.icon .fa-mobile {
  font-size: 1.8rem;
  color: #ec1c09;
}
.icon .fa-envelope-open {
  font-size: 1.8rem;
  color: #ff9a1a;
}

.icon .fa-user-tag,
.fa-house-laptop {
  font-size: 1.8rem;
  color: #ec1c09;
}

.icon .fa-star,
.fa-address-card {
  font-size: 1.8rem;
  color: #ec1c09;
}

.icon .fa-signal,
.fa-user-nurse,
.fa-city {
  font-size: 1.8rem;
  color: #ff9a1a;
}

.card-adresse .detail:not(.telspace) div {
  margin-left: 7px;
  margin-top: 0px;
}

.user-tel-title {
  color: rgba(26, 16, 3, 0.5);
  font-size: 14px;
  font-family: Raleway, serif;
  font-weight: 500;
}

.user-tel-phone {
  color: #000000;
  font-size: 14px;
  font-family: Raleway, serif;
  font-weight: 600;
}
.link-color-whatsap {
  color: #dc143c;
  font-size: 14px;
  font-family: Raleway, serif;
  font-weight: 600;
  text-decoration: underline;
}

.vue-star-rating-star[data-v-fde73a0c] {
  height: 20px;
  margin-left: -5px;
}

.flexInself {
  align-self: flex-end;
}


.pac-container {
  z-index: 10000 !important;
}

.flexibleImage{
  margin-bottom:1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
}

.textTitle::before{
  content:" ";
  display: inline-block;
  width : 50px;
  height:5.5px;
  background: #3aaa35;
  margin-right: 2rem;
}

.textTitle::after{
  content:" ";
  display: inline-block;
  width : 50px;
  height:5.5px;
  background: #3aaa35;
  margin-left: 2rem;
}

.r-bg-color{
  background:#3aaa35;
  color:#fff;
}

.border-rcolis{
border-color:#3aaa35 !important;
}


.formImage img{
  object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height:10rem;
}

.marginImage{
margin-bottom:1.2rem;
}


.flexible-gain-status{
  display:flex;
  justify-content:space-between;
  align-items:start;
  flex-wrap:wrap;

}

.gain-status{
  margin:23px;
  padding:10px ;
  border:3px inset #3aaa35; 
  background:rgba(242, 245, 249, 1);
  border-radius:10px;
  width:150px;
  height: 5.5rem;
}

.gain-status h3{
  font-size:17px;
  font-weight: 500;
  line-height:23px;
 white-space:nowrap;

}

.gain-status p{
  font-size:17px;
  font-weight: 700;
  line-height:40px;
  margin-top  :10px;
  color:#000000;
}
.margiN{
  margin-left: 3.6rem;
}




.otherBtn {
  margin: 0 auto;
}

.otherBtn button {
  width: 50px;
}

.buttonList {

display: flex;
align-items:center;
flex-direction: column;
justify-self: center;
}

.buttonList div button {
width: 100%;
}

@media only screen and (max-width: 768px) {
  .buttonList {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px;
    margin: 0 auto !important;
    width: 100%;
    place-item:center;
  }
  .otherBtn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  .buttonList button {
    width: 90%;
    margin: 0 auto;
  }
  .buttonList button.supprBtn {
    display: flex;
    align-items: flex-start;
    float: left;
  }
}


.imageProfile {
  border-radius: 15px;
  box-shadow: 0 15px 50px rgb(0, 0, 0, 0.35);
  width: 160px;
  height: 160px;
}



.imageProfile:hover {
  height: 200px;
  width: 200px;
}


.color-rmobility{
  background: #3aaa35;
  color: #fff;
}
</style>
